'use strict';

// @TODO: Remove if not used
var formValidation = require('base/components/formValidation');
var scrollAnimate = require('base/components/scrollAnimate');
var formHelpers = require('base/checkout/formErrors');
var base = require('./../product/base');
var prefixCombobox = require('../../../../../../int_ccf_omnichannel/cartridge/client/default/js/prefixCombobox/prefixCombobox');

/**
 * Create an alert to display the error message
 * @param {Object} form - Form in which to show the error message
 * @param {Object} message - Error message to display
 */
function createErrorNotification(form, message) {
    if (!$('#customerModification').length > 0) {
        if ($('.error-message')) {
            $('.error-message').remove();
        }
        var errorHtml = '<div class="alert alert-danger error-message" role="alert"> '
            + message + '</div>';
        scrollAnimate($('.error-message'));
        form.prepend(errorHtml);
        if ($('#notificationHref').length > 0) {
            var link = $('#helpLink').val();
            $('#notificationHref').attr('href', link);
        }
    }
}

/**
 * update an alert to display the error message
 * @param {Object} message - Error message to display
 */
function updateErrorNotification(message) {
    $('span.message-notification').text(message);
    scrollAnimate($('span.message-notification'));
}

/**
 * update an alert to display the error message
 * @param {Object} data - data info about the ajax request for build the new action url
 */
function updateURLActionForm(data) {
    var url = $('#actionURL');
    if (data.emailUpdated || data.phoneUpdated) {
        url += '?' + $('#targetEndPoint').val() + '&';
        if (data.emailUpdated) {
            url += 'isEmailNull=' + !data.emailUpdated;
        }
        if (data.phoneUpdated) {
            url += 'isPhoneNull=' + !data.phoneUpdated;
        }
        $('#customerCompleteFields').action = url;
    }
}

/**
 * Create an alert to display the success message
 * @param {Object} message - Success message to display
 */
function createSuccessNotification(message) {
    $('body').append('<div class="register-messages"></div>');
    $('.register-messages').append(
        '<div class="alert alert-success text-center register-success-alert"'
        + ' role="alert">' + message + '</div>'
    );
    setTimeout(function () {
        $('.register-success-alert').remove();
    }, 5000);
}

/**
 * Show login error modal
 * @param {Object} data - data response from login controller
 */
function showErrorModal(data) {
    $('#customerLoginModal').modal('hide');
    $('#customerLoginErrorModal').modal('show');
    $('h4.modal-error-title').text(data.errorTitle);
    $('#customerLoginErrorModal').find('.ca-modal-error p').text(data.errorMessage);
}

/**
 * Set invalid phone
 * @returns {boolean} True if an error is detected during validation, otherwise false.
 */
function setInvalidPhone() {
    var errorDetected = false;
    var phone = $('#phone').val();
    var prefix = prefixCombobox.getCountryCodeFromInput();

    if (phone === '' || !/^[0-9]{6,12}$/.test(phone)) {
        $('#phone').addClass('is-invalid');
        errorDetected = true;
    } else if (!prefixCombobox.validateFields(phone, prefix, true, true)) {
        $('#phone').addClass('is-invalid');
        $('#phone').next().html($('#phone').data('pattern-mismatch'));
        errorDetected = true;
    }

    if (errorDetected && $('#phoneError').length > 0) {
        $('#phoneError').removeClass('d-none');
    }

    return errorDetected;
}
/**
 * Set invalid prefix
 *
 * @returns {boolean} True if an error is detected during validation, otherwise false.
 */
function setInvalidPrefix() {
    var errorDetected = false;

    if ($('#phonePrefix').val() !== '' && !/[+]{1}[0-9/-]{1,5}$/.test($('#phonePrefix').val())) {
        $('#phonePrefix').addClass('is-invalid');
        errorDetected = true;
    } else {
        $('#myDropdownButton').removeClass('is-invalid');
    }

    if (prefixCombobox.validateFields($('#phone').val(), prefixCombobox.getCountryCodeFromInput(), true, true)
        && $('.email-phone-null').length > 0) {
        $('#phone').addClass('disabled');
        $('#phone').readOnly = true;
        $('#registrationPhonePrefixDropdown').addClass('disabled');
        $('#registrationPhonePrefixDropdown').readOnly = true;
    }

    return errorDetected;
}
/**
 * Set invalid email
 *
 * @returns {boolean} True if an error is detected during validation, otherwise false.
 */
function setInvalidMail() {
    var errorDetected = false;

    if ($('#email').val() === '') {
        $('#email').addClass('is-invalid');
        $('#email').next().html($('#email').data('missing-error'));
        errorDetected = true;
    } else if (!/^[\w.%+\-]+@[\w.\-]+\.[\w]{2,6}$/.test($('#email').val())) {
        $('#email').addClass('is-invalid');
        $('#email').next().html($('#email').data('pattern-mismatch'));
        errorDetected = true;
    }

    return errorDetected;
}

/**
 * Set invalid personal data if needed.
 * @returns {boolean} True if an error is detected during validation, otherwise false.
 */
function setInvalidPersonalData() {
    var errorDetected = false;
    if ($('#birthDate').val() === '') {
        $('#birthDate').addClass('is-invalid');
        errorDetected = true;
    }
    return errorDetected;
}

/**
 * Set invalid address data
 * @returns {boolean} True if an error is detected during validation, otherwise false.
 */
function setInvalidAddressData() {
    var errorDetected = false;
    var postalCode = $('#postalCode').val();
    var isValidPostalCodeFormat = /(^\d{5}(-\d{4})?$)|(^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Za-z]{1} *\d{1}[A-Za-z]{1}\d{1}$)/.test(postalCode);

    if ($('#address').val() === '') {
        $('#address').addClass('is-invalid');
        errorDetected = true;
    }
    if (postalCode === '') {
        $('#postalCode').next().html($('#postalCode').data('missing-error'));
        $('#postalCode').addClass('is-invalid');
        errorDetected = true;
    } else if (!isValidPostalCodeFormat || (postalCode.length < 5 || postalCode.length > 10)) {
        $('#postalCode').next().html($('#postalCode').data('pattern-mismatch'));
        $('#postalCode').addClass('is-invalid');
        errorDetected = true;
    }
    if ($('#city').val() === '') {
        $('#city').addClass('is-invalid');
        errorDetected = true;
    }
    if ($('#province').children('option:selected').val() === '') {
        $('#province').addClass('is-invalid');
        errorDetected = true;
    }

    return errorDetected;
}
/**
 * Site redirect
 * @param {Object} element - element in DOM
 */
function siteRedirect(element) {
    var action = $('.page').attr('data-action');
    if (action === '' || action === null) {
        action = 'Home-Show';
    }
    var queryString = $('.page').attr('data-querystring');
    var customerType = element.attr('data-customer-type');
    var geolocation = element.attr('data-geolocation');
    var changeSiteUrl = $('#customerLogin').find('.ca_btn_entrar').attr('data-change-site');
    $.ajax({
        url: changeSiteUrl,
        type: 'post',
        dataType: 'json',
        data: {
            customerType: customerType,
            geoLocation: geolocation,
            action: action,
            queryString: queryString
        },
        success: function (response) {
            $.spinner().stop();
            if (response && response.redirectUrl) {
                window.location.href = response.redirectUrl;
            }
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Reload User on PDP login
 */
function reloadUser() {
    var $header = $('.navbar-header .headerInclude');
    var url = $header.data('remoteinclude');
    $.ajax({
        url: url,
        method: 'get',
        context: $header,
        success: function (data) {
            if (data.renderedTemplate) {
                $($header).html(data.renderedTemplate);
                // Init required events when html template is available
                if ($($header).hasClass('headerInclude')) {
                    if ($('.ca_user_name.user').length) {
                        $('.ca_user_name.user').on('mouseenter', function () {
                            $('.terminalist-opts').hide();
                            $('.user-opts').toggle();
                        });
                    }
                }
                if ($('.add-to-cart-unlogged')) {
                    $('.add-to-cart-unlogged')
                        .removeAttr('data-target')
                        .removeAttr('data-toggle')
                        .removeAttr('data-pdp-action');
                    $('.add-to-cart-unlogged').addClass('add-to-cart').removeClass('add-to-cart-unlogged');
                }
            }
        }
    });
}

/**
 * Reload MiniCart on PDP login
 */
function reloadMinicart() {
    var $miniCart = $('.nav-item .minicart');
    var urlMinicart = $miniCart.find('[data-remoteinclude]').data('remoteinclude');
    $.ajax({
        url: urlMinicart,
        method: 'get',
        context: $miniCart,
        success: function (data) {
            if (data.renderedTemplate) {
                $($miniCart).html(data.renderedTemplate);
                base.addToCartFunction('button.add-to-cart');
            }
        }
    });
}

module.exports = {

    login: function () {
        $('form#customerLogin').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');

            var action = $('#currentAction').val();
            if (!action || action === '') {
                $('#currentAction').val($('.page').data('action'));
                $('#currentQueryString').val($('.page').data('querystring'));
            }

            form.spinner().start();
            $('form#customerLogin').trigger('login:submit', e);

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        showErrorModal(data);
                        $('#customerLoginErrorModal').find('.close').attr('data-site-redirect', false);
                        $('.ca-modal-actions').removeClass('d-flex');
                    } else {
                        var isPhoneValidPrefix = prefixCombobox.validateFields(data.phone, data.phonePrefix, true, true);
                        if (data.redirectUrl && data.redirectUrl.includes('?')) {
                            data.redirectUrl += '&isPhoneValidPrefix=' + isPhoneValidPrefix.toString();
                        }
                        // eslint-disable-next-line no-lonely-if
                        if (data.isCBK) { // customer can be created, show registration form
                            showErrorModal(data);
                            $('#customerLoginErrorModal').find('.close').attr('data-site-redirect', false);
                            $('.ca-modal-actions').addClass('d-flex');
                        } else if (data.isCustomerInvalid) {
                            showErrorModal(data);
                            $('#customerLoginErrorModal').find('.close').attr('data-site-redirect', true);
                            $('#customerLoginErrorModal').find('.close').attr('data-customer-type', data.customerType);
                            $('#customerLoginErrorModal').find('.close').attr('data-geolocation', data.geolocation);
                            $('.ca-modal-actions').removeClass('d-flex');
                        } else if (data.addToCart) {
                            $('.ca-modal-actions').hide();
                            $('#customerLoginModal').modal('hide');
                            reloadUser();
                            reloadMinicart();
                        } else if (data.vo && !$.isEmptyObject(data.vo)) {
                            $('.ca-modal-actions').hide();
                            $('#customerLoginModal').modal('hide');
                            if (data.vo.error) {
                                $.spinner().stop();
                                $('#errorModalVO').modal('show');
                            } else {
                                $.spinner().stop();
                                $('#redirectModal').modal('show');
                                setTimeout(function () {
                                    window.location.href = data.vo.redirectUrl;
                                }, 3000);
                            }
                        } else {
                            $('.ca-modal-actions').hide();
                            window.location.href = data.redirectUrl;
                        }
                    }
                },
                error: function (data) {
                    if (data.responseJSON && data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form#customerLogin').trigger('login:error', data);
                        form.spinner().stop();
                    }
                }
            });
            return false;
        });
    },

    register: function () {
        $('form#customerRegistration').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');

            form.spinner().start();
            $('form#customerRegistration').trigger('register:submit', e);

            var docType = $('#documentType').val();
            $('#customerRegistration #documentTypeSelected').val(docType);

            if ($('.is-invalid').length === 0) {
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: form.serialize(),
                    success: function (data) {
                        form.spinner().stop();
                        if (data.success === false) {
                            if (data.fieldErrors && data.fieldErrors.length) {
                                data.fieldErrors.forEach(function (error) {
                                    if (Object.keys(error).length) {
                                        formHelpers.loadFormErrors('.customer-login', error, false);
                                        if ($('#phone').hasClass('is-invalid') || $('#phonePrefix').hasClass('is-invalid')) {
                                            $('.ca-btn').prop('disabled', true);
                                        }
                                    }
                                });
                                createErrorNotification(form, data.error);
                            } else {
                                createErrorNotification(form, data.error);
                            }
                        } else if (data.isCustomerInvalid) {
                            showErrorModal(data);
                            $('.page').attr('data-action', '');
                            $('#customerLoginErrorModal').find('.close').attr('data-site-redirect', true);
                            $('#customerLoginErrorModal').find('.close').attr('data-customer-type', data.customerType);
                            $('#customerLoginErrorModal').find('.close').attr('data-geolocation', data.geolocation);
                            $('.ca-modal-actions').removeClass('d-flex');
                        } else {
                            createSuccessNotification(data.message);
                            window.location.href = data.redirectUrl;
                        }
                    },
                    error: function (data) {
                        if (data.responseJSON && data.responseJSON.redirectUrl) {
                            window.location.href = data.responseJSON.redirectUrl;
                        } else {
                            createErrorNotification(form, data.error);
                            form.spinner().stop();
                        }
                    }
                });
                return false;
            }
            form.spinner().stop();
            return false;
        });
    },

    clearResetForm: function () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
        $('#customerLoginModal .modal').on('hidden.bs.modal', function () {
            $('.ca-modal-error p').text('');
        });
    },

    triggerSiteRedirect: function () {
        $('#customerLoginErrorModal').on('hidden.bs.modal', function () {
            var closeBtn = $(this).find('.close');
            if (closeBtn && closeBtn.attr('data-site-redirect') === 'true') {
                siteRedirect(closeBtn);
            }
        });
    },

    // In case of Login in PLP (Infinity Product) set action to PDP after customer login
    controlInfinityLogin: function () {
        $('body').on('click', '.suscription-product', function (e) {
            e.preventDefault();
            var suscriptionAction = $(this).attr('data-pdp-action');
            var suscriptionQueryString = $(this).attr('data-pdp-query');
            var suscriptionMirrorQueryString = $(this).attr('data-pdp-query-mirror');
            var customerLoginModal = $('#customerLoginModal');
            customerLoginModal.find('#currentAction').val(suscriptionAction);
            customerLoginModal.find('#currentQueryString').val(suscriptionQueryString);
            customerLoginModal.find('#currentMirrorQueryString').val(suscriptionMirrorQueryString);
            customerLoginModal.modal();
        });
        $('body').on('hidden.bs.modal', '#customerLoginModal', function () {
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
            $(this).find('#currentAction').val('');
            $(this).find('#currentQueryString').val('');
            $(this).find('#currentMirrorQueryString').val('');
        });
    },

    // infinity customer
    registerInfinity: function () {
        $('.infinity-registration').submit(function (e) {
            e.preventDefault();
            var $form = $(this);
            $.ajax({
                url: $form.attr('action'),
                type: 'post',
                data: $form.serialize(),
                success: function (data) {
                    if (data.success) {
                        $('#infinityRegistration').modal();
                    } else if (data.fields) {
                        formHelpers.loadFormErrors($form, data.fields);
                    } else {
                        createErrorNotification($form, data.error);
                    }
                },
                error: function (data) {
                    if (data.responseJSON && data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($form, data.error);
                    }
                }
            });
        });
    },

    // In case of Login in PDP set action after customer login
    controlPdpLogin: function () {
        $('body').on('click', '.add-to-cart-unlogged', function () {
            var pdpAction = $(this).attr('data-pdp-action');
            var customerLoginModal = $('#customerLoginModal');
            customerLoginModal.find('#currentAction').val(pdpAction);
        });
    },
    // In case of Login in VO category set action after customer login
    controlVOLogin: function () {
        $('body').on('click', '.bannerLogin', function () {
            var pdpAction = $(this).attr('data-pdp-action');
            var customerLoginModal = $('#customerLoginModal');
            customerLoginModal.find('#currentAction').val(pdpAction);
        });
    },

    editFieldsEmailAndPhoneWhenNull: function () {
        $('form#customerCompleteFields').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');

            form.spinner().start();
            $('form#customerCompleteFields').trigger('editFieldsEmailAndPhoneWhenNull:submit', e);
            var termsAndConditions = $('.custom-control-input', '.custom-checkbox');

            if (!termsAndConditions.is(':checked')) {
                termsAndConditions.addClass('is-invalid');
            } else {
                termsAndConditions.removeClass('is-invalid');
            }

            var docType = $('#documentType').val();
            $('#customerCompleteFields #documentTypeSelected').val(docType);

            if ($('.is-invalid').length === 0) {
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: form.serialize(),
                    success: function (data) {
                        form.spinner().stop();
                        if (data.success === false) {
                            if (data.fieldErrors && data.fieldErrors.length) {
                                data.fieldErrors.forEach(function (error) {
                                    if (Object.keys(error).length) {
                                        formHelpers.loadFormErrors('.customer-login', error, false);
                                        if ($('#phone').hasClass('is-invalid')) {
                                            $('#registerSubmitPhoneEmail').prop('disabled', true);
                                        }
                                    }
                                });
                                if (data.emailUpdated === 409) {
                                    $('#email').addClass('is-invalid');
                                    $($('#email').next()).text($('#email').attr('data.alreadyinuse-error'));
                                }
                            } else {
                                if (data.emailUpdated === true) {
                                    $('#email').prop('disabled', true);
                                } else if (data.emailUpdated === 409) {
                                    $('#email').addClass('is-invalid');
                                    $($('#email').next()).text($('#email').attr('data.alreadyinuse-error'));
                                }
                                if (data.phoneUpdated === true) {
                                    $('#prefix').prop('disabled', true);
                                    $('#phone').prop('disabled', true);
                                }
                                updateURLActionForm(data);
                                updateErrorNotification(data.error);
                            }
                        } else {
                            $.ajax({
                                url: data.redirectUrl,
                                type: 'post',
                                dataType: 'json',
                                data: form.serialize(),
                                success: function (data2) {
                                    if (data2.success) {
                                        window.location.href = data2.redirectUrl;
                                    }
                                },
                                error: function (data2) {
                                    if (data2.responseJSON && data2.responseJSON.redirectUrl) {
                                        window.location.href = data2.responseJSON.redirectUrl;
                                    } else {
                                        updateErrorNotification(data2.error);
                                        form.spinner().stop();
                                    }
                                }
                            });
                        }
                        // form.spinner().stop();
                    },
                    error: function (data) {
                        if (data.responseJSON && data.responseJSON.redirectUrl) {
                            window.location.href = data.responseJSON.redirectUrl;
                        } else {
                            updateErrorNotification(data.error);
                            form.spinner().stop();
                        }
                    }
                });
            }
            form.spinner().stop();
            return false;
        });
    },

    clickSubmitDocumentOnReady: () => {
        $('#registerSubmitPhoneEmail').click();
    },

    showErrorWhenIsBankEmail: () => {
        if ($('.is-bank-email').length > 0) {
            $('#email').addClass('is-invalid');
            $('.is-bank-email').addClass('d-block');
        }
    },

    checkCaixaEmail: () => {
        $('#email').keyup(function () {
            var email = $('#email').val();
            var atSymbolIndex = email.indexOf('@');
            var lastDotSymbolIndex = email.lastIndexOf('.');
            if ((email.slice(atSymbolIndex + 1, lastDotSymbolIndex).toLowerCase() === 'caixabank'
                || email.slice(atSymbolIndex + 1, lastDotSymbolIndex).toLowerCase() === 'caixa')) {
                $('#email').addClass('is-invalid');
                $('#email').next().addClass('d-block');
                $('#email').next().html($('#email').attr('caixa-error'));
                $('button.ca-btn').prop('disabled', true);
            } else {
                $('#email').removeClass('is-invalid');
                $('#email').next().removeClass('d-block');
                $('button.ca-btn').prop('disabled', false);
            }
        });
    },

    phoneInvalid: () => {
        var errorDetected = false;
        setInvalidPhone();
        setInvalidPrefix();
        setInvalidMail();
        if ($('.register-form').length > 0 || $('.email-phone-null').length > 0 || $('.customer-modification').length > 0) {
            setInvalidAddressData();
            setInvalidPersonalData();
            $('button.ca-btn').on('click', function () {
                errorDetected = false;
                setInvalidPersonalData();
                errorDetected = setInvalidAddressData() || errorDetected;
                errorDetected = setInvalidPhone() || errorDetected;
                errorDetected = setInvalidPrefix() || errorDetected;
                errorDetected = setInvalidMail() || errorDetected;

                var form = $(this).closest('form');
                var errorMessage = $('#errorMessage').val();

                if (errorDetected) {
                    createErrorNotification(form, errorMessage);
                }
            });
        }
    },

    caixabankEmail: () => {
        if ($('.email-phone-null').length > 0) {
            $('#registerSubmitPhoneEmail').on('click', function () {
                var email = $('#email').val().toLowerCase();
                var atSymbolIndex = email.indexOf('@');
                var lastDotSymbolIndex = email.lastIndexOf('.');
                if (atSymbolIndex > -1 && lastDotSymbolIndex > -1
                    && (email.slice(atSymbolIndex + 1, lastDotSymbolIndex).toLowerCase() === 'caixabank'
                    || email.slice(atSymbolIndex + 1, lastDotSymbolIndex).toLowerCase() === 'caixa')) {
                    $('#email').addClass('is-invalid');
                    $($('#email').next()).text($('#email').attr('data-email-error'));
                }
                var form = $('#customerCompleteFields');
                var url = form.attr('action');

                form.spinner().start();
                $('form#customerCompleteFields').trigger('editFieldsEmailAndPhoneWhenNull:submit');
                var termsAndConditions = $('.custom-control-input', '.custom-checkbox');

                if (!termsAndConditions.is(':checked')) {
                    termsAndConditions.addClass('is-invalid');
                } else {
                    termsAndConditions.removeClass('is-invalid');
                }

                var docType = $('#documentType').val();
                $('#customerCompleteFields #documentTypeSelected').val(docType);
                if (prefixCombobox.validateFields($('#phone').val(), prefixCombobox.getCountryCodeFromInput(), true, true)) {
                    $('#phone').addClass('disabled');
                    $('#phone').readOnly = true;
                    $('#registrationPhonePrefixDropdown').addClass('disabled');
                    $('#registrationPhonePrefixDropdown').readOnly = true;
                }
                if ($('.is-invalid').length === 0) {
                    $.ajax({
                        url: url,
                        type: 'post',
                        dataType: 'json',
                        data: form.serialize(),
                        success: function (data) {
                            form.spinner().stop();
                            if (data.success === false) {
                                if (data.fieldErrors && data.fieldErrors.length) {
                                    data.fieldErrors.forEach(function (error) {
                                        if (Object.keys(error).length) {
                                            formHelpers.loadFormErrors('.customer-login', error, false);
                                            if ($('#phone').hasClass('is-invalid')) {
                                                $('.ca-btn').prop('disabled', true);
                                            }
                                        }
                                    });
                                    if (data.emailUpdated === 409) {
                                        // $('#email').addClass('is-invalid');
                                        // $($('#email').next()).text($('#email').attr('data.alreadyinuse-error'));
                                        $('.register-alert').addClass('d-none');
                                        createErrorNotification(form, data.error);
                                        return;
                                    }
                                    updateURLActionForm(data);
                                    createErrorNotification(form, data.error);
                                } else {
                                    if (data.emailUpdated === true) {
                                        $('#email').prop('disabled', true);
                                    } else if (data.emailUpdated === 409) {
                                        $('.register-alert').addClass('d-none');
                                        createErrorNotification(form, data.error);
                                        return;
                                    } else {
                                        $('.register-alert').addClass('d-none');
                                        createErrorNotification(form, data.error);
                                        return;
                                    }
                                    if (data.phoneUpdated === true) {
                                        $('#prefix').prop('disabled', true);
                                        $('#phone').prop('disabled', true);
                                    }
                                    updateURLActionForm(data);
                                    createErrorNotification(form, data.error);
                                }
                            } else {
                                $.ajax({
                                    url: data.redirectUrl,
                                    type: 'post',
                                    dataType: 'json',
                                    data: form.serialize(),
                                    success: function (data2) {
                                        if (data2.success) {
                                            window.location.href = data2.redirectUrl;
                                        } else {
                                            $('.register-alert').addClass('d-none');
                                            createErrorNotification(form, data2.errorMessage);
                                        }
                                    },
                                    error: function (data2) {
                                        if (data2.responseJSON && data2.responseJSON.redirectUrl) {
                                            window.location.href = data2.responseJSON.redirectUrl;
                                        } else {
                                            updateErrorNotification(data2.error);
                                            form.spinner().stop();
                                        }
                                    }
                                });
                            }
                            form.spinner().stop();
                        },
                        error: function (data) {
                            if (data.responseJSON && data.responseJSON.redirectUrl) {
                                window.location.href = data.responseJSON.redirectUrl;
                            } else {
                                updateErrorNotification(data.error);
                                form.spinner().stop();
                            }
                        }
                    });
                }
                form.spinner().stop();
                return false;
            });
        }
    },

    caixabankPhone: () => {
        if ($('#phone').hasClass('is-invalid')) {
            $('#phone').on('focus', function () {
                $('.ca-btn').prop('disabled', false);
            });
        }
    },

    emailNotDeclared: () => {
        if ($('#email').val() === '') {
            $('#email').addClass('is-invalid');
            $('.form-email-summary').removeClass('is-invalid');
        }
    }
};
